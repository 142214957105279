<template>
  <div class="Investigation">
    <div class="Investigation-problem" v-for="(item,index) in investigationList" :key="index">
      <div class="Investigation-problem-title">
        {{item.title}}
      </div>
      <div class="Investigation-problem-answer">
        <van-radio-group v-model="item.problumValue">
          <van-radio :name="ite.id" style="marginBottom: 15px" v-for="(ite,ind) in item.problems" :key="ind">{{ite.value}}</van-radio>
        </van-radio-group>
      </div>
    </div>
    <van-button type="primary" size="large" style="display:block;width: 90%;margin: 15px auto;" @click="submit">提交</van-button>
  </div>
</template>

<script>
import Investigation from "./Investigation";
export default Investigation;
</script>

<style lang="scss" scoped>
.Investigation {
  width: 375px;
  height: 100%;
  background: #f2f2f4;
  overflow: auto;
  &-problem {
    width: 375px;
    padding: 15px;
    margin: 5px 0;
    background: white;
    box-sizing: border-box;
    &-title {
      font-size: 14px;
      font-weight: 600;
      white-space: wrap;
      margin-bottom: 15px;
    }
    &-answer {
      width: 100%;
    }
  }
}
</style>