import axios from "axios"
const service = axios.create({
    baseURL:'https://api.operation.lejian.com', //是用于请求的服务器 URL
    timeout: 5000, // 请求超时时间 如果请求话费了超过 `timeout` 的时间，请求将被中断
});
// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 对响应错误做点什么
    
    return Promise.reject(error);
});

export default service