import { ref } from 'vue';
import { Toast } from "vant"
import servers from "@/utils/http.js"
export default {
    data() {
        return {
            investigationList: [{
                "title": "1：您对本次就医的总体服务是否满意？",
                "problumValue": "",
                "problems": [{
                    "value": "1分: 非常不满意",
                    "id": 1
                }, {
                    "value": "2分: 不满意",
                    "id": 2
                }, {
                    "value": "3分: 基本满意",
                    "id": 3
                }, {
                    "value": "4分: 满意",
                    "id": 4
                }, {
                    "value": "5分: 非常满意",
                    "id": 5
                }]
            }, {
                "title": "2：您对就医医院的便利性（如所处位置、交通等）是否满意？",
                "problumValue": "",
                "problems": [{
                    "value": "1分: 非常不满意",
                    "id": 1
                }, {
                    "value": "2分: 不满意",
                    "id": 2
                }, {
                    "value": "3分: 基本满意",
                    "id": 3
                }, {
                    "value": "4分: 满意",
                    "id": 4
                }, {
                    "value": "5分: 非常满意",
                    "id": 5
                }]
            }, {
                "title": "3:您对此次就诊医生是否满意？",
                "problumValue": "",
                "problems": [{
                    "value": "1分: 非常不满意",
                    "id": 1
                }, {
                    "value": "2分: 不满意",
                    "id": 2
                }, {
                    "value": "3分: 基本满意",
                    "id": 3
                }, {
                    "value": "4分: 满意",
                    "id": 4
                }, {
                    "value": "5分: 非常满意",
                    "id": 5
                }]
            }, {
                "title": "4:您对本次提供服务人员是否满意？",
                "problumValue": "",
                "problems": [{
                    "value": "1分: 非常不满意",
                    "id": 1
                }, {
                    "value": "2分: 不满意",
                    "id": 2
                }, {
                    "value": "3分: 基本满意",
                    "id": 3
                }, {
                    "value": "4分: 满意",
                    "id": 4
                }, {
                    "value": "5分: 非常满意",
                    "id": 5
                }]
            }, {
                "title": "5：您此次就医服务流程是否顺畅？",
                "problumValue": "",
                "problems": [{
                    "value": "1分: 非常不顺畅",
                    "id": 1
                }, {
                    "value": "2分: 不顺畅",
                    "id": 2
                }, {
                    "value": "3分: 基本顺畅",
                    "id": 3
                }, {
                    "value": "4分: 顺畅",
                    "id": 4
                }, {
                    "value": "5分: 非常顺畅",
                    "id": 5
                }]
            }]
        }
    },
    setup() {
        const checked = ref('1');
        return { checked };
    },
    methods: {
        async submit() {
            let newArry = []
            this.investigationList.forEach(item => {
                newArry.push({
                    "question": item.title,
                    "score": item.problumValue
                })
            })
            if (newArry.some(item => item.score == '') == false) {
                let data = {
                    "orderId": window.location.hash.slice(window.location.hash.indexOf("?") + 1),
                    "questionMedcials": newArry
                }
                let res = await servers.post(`/medical/question/submitQuestion`, data)
                if (res && res.data.success) {
                    Toast.success(res.data.message)
                    setTimeout(() => {
                        window.parent.window.location.reload()
                    }, 1000)
                } else {
                    Toast.fail(res.data.message)
                    setTimeout(() => {
                        window.parent.window.location.reload()
                    }, 1000)

                }
            } else {
                Toast.fail("请选择完所有的调查项")
            }
        }
    }
}